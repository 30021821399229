import * as React from "react"
import FeaturedProductsSlider from "../products/FeaturedProductsSlider"
import PropTypes from "prop-types"

const BrandGallery = ({ section, className = "" }) => {
  let allProducts = []
  // sort categories by priority
  section.categories.sort((a, b) => {
    return a.priority - b.priority
  })
  section.categories.forEach(category => {
    category.gallery.map(item => {
      allProducts.push(item)
    })
  })

  return (
    <section className={className || "flex flex-col"}>
      <FeaturedProductsSlider products={allProducts} title={section?.heading} />
    </section>
  )
}
BrandGallery.propTypes = {
  section: PropTypes.object.isRequired,
  className: PropTypes.string,
}

export default BrandGallery
